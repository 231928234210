// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/contact.yml'
import layout from 'content/layout.yml'

// Contact & info page
const ContactPage = () => {
  return <Page content={{ ...layout, ...content }} />
}

export default ContactPage

// COMPONENTS
import React from 'react'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'
import Title from 'layout/Title'
import Body from 'layout/Body'
import PostFooter from 'BlogPost/PostFooter'
import SimpleSignUp from 'components/SignUp/simple'
import ssContent from 'content/stickySignup.yml'

// Default page template
const Page = ({ content, children, error, signup }) => {
  const { layout, title, subtitle, image, socials, body } = content
  return (
    <Layout header={layout.header} stickyFooter={ssContent.default && <SimpleSignUp content={ssContent.default} />}>
      <SEO title={title} image={image} />
      <Title
        title={title}
        subtitle={subtitle}
        image={image}
        error={error}
        roundedImage={socials ? true : false}
        socials={socials}
      />
      <Body markdown={body} />
      {children}
      { signup && <PostFooter signup={signup} inverted /> }
    </Layout>
  )
}

Page.defaultProps = {
  error: false,
  signup: true,
}

export default Page
